import analytics1 from '../../assets/pictures/dashBoardPictures/analytics1.png';
import analytics2 from '../../assets/pictures/dashBoardPictures/analytics2.png';
import analytics3 from '../../assets/pictures/dashBoardPictures/analytics3.png';
import analytics4 from '../../assets/pictures/dashBoardPictures/analytics4.png';

export const analytics = [
  {
    id: 0,
    img: analytics2,
    title: "Dashboard.dashboard1.title",
    text: "Dashboard.dashboard1.description",
    credo: "Dashboard.dashboard1.button"
  },
  {
    id: 1,
    img: analytics3,
    title: "Dashboard.dashboard2.title",
    text: "Dashboard.dashboard2.description",
    credo: "Dashboard.dashboard2.button"
  },
  {
    id: 2,
    img: analytics1,
    title: "Dashboard.dashboard3.title",
    text: "Dashboard.dashboard3.description",
    credo: "Dashboard.dashboard3.button"
  },
  {
    id: 3,
    img: analytics4,
    title: "Dashboard.dashboard4.title",
    text: "Dashboard.dashboard4.description",
    credo: "Dashboard.dashboard4.button"
  },
]