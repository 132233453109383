import React, { useState, useMemo } from 'react';
import logo from '../../assets/pictures/logo.svg';
import Select from 'react-select';
import Arrow from '../../assets/pictures/arrow.svg';
import style from './HeaderStyle.module.css';
import './SelectStyles.css';
import { useTranslation } from 'react-i18next';

const Header = ({handleScroll, changeLanguage, languages}) => {

  const { t, i18n } = useTranslation()
  const currentLng = i18n.language;

  const options = useMemo(() => {
    return languages.map((lng) => ({
      value: lng.locale,
      label: lng.image_flag
    }))
  }, [languages]);

  const handleChange = (lng) => {
    changeLanguage(lng.value);
  }
  const redirectLogIn = () => {
    window.location.href="https://app.fleetinsider.eu/login"
  }
  const redirectSignUp = () => {
    window.location.href="https://app.fleetinsider.eu/register"
  }

  return (
    <div className={style.headerWrapper}>
      <div className={`${style.header} ${style.container}`}>
        <div className={style.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={style.buttons}>
          <button onClick={() => handleScroll("ContactUs")} className={style.buttonContact}>{t('Header.Contact Us')}</button>
          <Select
            className={"react-select-container"}
            classNamePrefix="react-select"
            components={{ DropdownIndicator:() => <img className={style.selectImg} src={Arrow} alt=" "/>, IndicatorSeparator:() => null }}
            defaultValue={options.find(e=>e.value === currentLng)}
            isSearchable={false}
            options={options}
            onChange={handleChange}
          />
          <div className={style.login}>
            <button className={style.button} onClick={redirectLogIn}>{t('Header.Log In')}</button>
            <div className={style.border}></div>
            <button className={style.button} onClick={redirectSignUp}>{t('Header.Sign UP')}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
