import React from 'react';
import FooterLogo from '../../assets/pictures/footerLogo.svg';
import { useTranslation } from 'react-i18next';
import style from './ResponsiveFooter.module.css';

export const ResponsiveFooter = ({scroll, changeLanguage}) => {
  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;
  
  const handleSignUp = () => {
    // navigate('/login', {state: {name: 'signup'}});
    window.location.href="https://app.fleetinsider.eu/register"
  }
  return(
    <div className={style.container}>
      <div className={style.logoWrapper}>
        <img className={style.logo} src={FooterLogo} alt=" " />
        <div className={style.subTitle}>{t("Footer.Increase efficiency, reduce and control costs and more all in one place")}</div>
        <div className={style.buttonWrapper}>
          <button className={style.button} onClick={handleSignUp}>{t("Footer.Sign up")}</button>
        </div>
      </div>
      <div className={style.linksWrapper}>
        <div className={style.featuresWrapper}>
          <div onClick={() => scroll('Features')} className={style.link}>{t("Footer.Features")}</div>
          <div onClick={() => scroll('Benefits')} className={style.link}>{t("Footer.Benefits")}</div>
          <div onClick={() => scroll('ContactUs')} className={style.link}>{t("Footer.Contact Us")}</div>
          <div className={style.languages}>
            <div onClick={() => {changeLanguage({target: {value: 'ENG'}})}} className={currentLng ==="ENG" ? style.activeLanguage : style.language}>ENG</div>
            <div className={style.border}></div> 
            <div onClick={() => {changeLanguage({target: {value: 'PL'}})}} className={currentLng ==="PL" ? style.activeLanguage : style.language}>PL</div></div>
        </div>
        <div className={style.termsWrapper}>
          <div className={style.link}>{t("Footer.Terms & Condition")}</div>
          <div className={style.link}>{t("Footer.Privacy policy")}</div>
        </div>
      </div>
    </div>
  )
}