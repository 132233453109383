import React from 'react';
import { analytics } from '../Dashboard/analytics';
import { useTranslation } from 'react-i18next';
import LineLiDashboard from '../../assets/pictures/LineLiDashboard.svg';
import style from './ResponsiveDashboard.module.css';

export const ResponsiveDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className={style.Dashboard}>
    <div className={style.container}>
      <div className={style.titleWrapper}>
        <div><img src={LineLiDashboard} alt=" " /></div>
        <div className={style.title}>
          {t("Dashboard.All analytics in")}
          {" "}{t("Dashboard.one place with")}
        </div>
      </div>
      <div className={style.contentContainer}>
        {analytics.map((dashboard) => {
          return(
            <div key={dashboard.id} className={style.dashboardWrapper}>
              <div className={style.dashboardTitle}>{t(dashboard.title)}</div>
              <div className={style.dashboardDescription}>{t(dashboard.text)}</div>
              <div className={style.dashboardImg}>
                <img src={dashboard.img} className={style.analyticsImg} alt=" " />
              </div>
              <div className={style.dashboardCredo}>{t(dashboard.credo)}</div>
            </div>
          )
        })}
        {/* <div className={style.descriptionWrapper}>
          <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.descriptionTitle}` : `${style.AnalyticsImgWrapper} ${style.descriptionTitle}`}>
            {
              analytics.map((e) => {
                if(e.id === imageId){
                  return t(e.title)
                }
              })
            }
          </div>
          <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.description}` : `${style.AnalyticsImgWrapper} ${style.description}`}>
            {
            analytics.map((e) => {
              if(e.id === imageId){
                return (
                  <span key={e.id}>{t(e.text)}</span>
                )
              }
            })
            } 
          </div>
          <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper} ${style.descriptionButton}` : `${style.AnalyticsImgWrapper} ${style.descriptionButton}`}>
            {
              analytics.map((e) => {
                if(e.id === imageId){
                  return t(e.credo);
                }
              })
            } 
          </div>
        </div>
        <div className={style.dashboard}>
          <div className={activeSlideContent ? `${style.ActiveAnalyticsImgWrapper}` : `${style.AnalyticsImgWrapper}`}>
            {
              analytics.map((e) => {
                if(e.id === imageId){
                  return (
                    <div key={e.id} className={`${style.imgWrapper}`}>
                      <img src={e.img} className={style.analyticsImg} alt=" " />
                    </div>
                  )
                }
              })
            }
          </div>
        </div> */}
      </div>
    </div>
  </div>
  )
}
