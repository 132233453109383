import img1 from '../../assets/pictures/img1.svg'; 
import activeImg1 from '../../assets/pictures/activeImg1.svg';
import img2 from '../../assets/pictures/cardLogo.svg';
import activeImg2 from '../../assets/pictures/activeCardLogo.svg';
import img3 from '../../assets/pictures/img3.svg';
import activeImg3 from '../../assets/pictures/activeImg3.svg'; 


export const cards = [
  {id: 1, 
   title: 'Cost savings',
   subTitle: 'Using data 1st tile',
   activeImg: activeImg1,
   Img: img1,
  },
  {id: 2, 
   title: 'Improved efficiency', 
   subTitle: 'Using data 2nd tile',
   activeImg: activeImg2,
   Img: img2,
  },
  {id: 3, 
   title: 'Enhanced decision-making', 
   subTitle: 'Using data 3rd tile',
   activeImg: activeImg3,
   Img: img3,
  },
];