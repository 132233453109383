import React, { useEffect } from "react";
import video from "../../assets/pictures/video.mp4";
import { useTranslation } from 'react-i18next';
import style from "./BlockFirst.module.css";

export const BlockFirst = () => {
  const { t } = useTranslation()
  return (
    <div className={style.firstBlock}>
      <video id="myVideo" className={style.video} autoPlay loop muted preload="auto">
        <source src={video} type="video/mp4" />
      </video>
      <div className={style.container}>
        <div className={style.textContainer}>
          <div className={style.Title}>
            {t("FirstBlock.See Better")}<br/>{t("FirstBlock.Operate Smarter")}
          </div>
          <div className={style.subTitle}>{t("FirstBlock.Increase")}<br/>{t("FirstBlock.Costs")}</div>
        </div>
      </div>
    </div>
  )
}
