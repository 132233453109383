import React from "react";
import Header from "../../components/Header/Header.jsx";
import { BlockFirst } from "../../components/BlockFirst/BlockFirst.jsx";
import { BlockSecond } from "../../components/BlockSecond/BlockSecond.jsx";
import { BlockThird } from "../../components/BlockThird/BlockThird.jsx";
import { BlockFourth } from "../../components/BlockFourth/BlockFourth.jsx";
import { BlockFifth } from "../../components/BlockFifth/BlockFifth.jsx";
import { useTranslation } from 'react-i18next';
import { Structure } from "../../components/Structure/Structure.jsx";
import { Dashboard } from "../../components/Dashboard/Dashboard.jsx";
import { ResponsiveDashboard } from "../../components/ResponsiveDashboard/ResponsiveDashboard.jsx";
// import TagManager from "react-gtm-module";
// import { useQuery } from 'react-query'
// import { api } from '../../api/http'

const LandingPage = () => {

  // const tagManagerArgs = {
  //   gtmId: 'G-CVT0ZDT0N0'
  // }
  // TagManager.initialize(tagManagerArgs)

  // const languageQuery = useQuery({
  //   queryFn: () => api.settings(),
  //   queryKey: ['languages']
  // })

  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});
  };
  const { i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng)
  }

  // const languages = languageQuery?.data?.data?.data;
  const languages = [{
    "locale": "pl",
    "image": "https://api.fleetinsider.eu/storage/lang/KXfyys7fiCohryviUPHUAP7Oa5LxWPFuBtXxcPak.png",
    "image_flag": "🇵🇱"
  },
    {
      "locale": "en",
      "image": "https://api.fleetinsider.eu/storage/lang/d9ZwUmAIjcYuoU8ZET2ic1bNO16pJUCnnBqtDPJk.png",
      "image_flag": "🇬🇧"
    },
    {
      "locale": "ua",
      "image": "https://api.fleetinsider.eu/storage/lang/XRLGIltTSAIlN7MXo9MLqMxKqU3sySoD0m72Yt7o.png",
      "image_flag": "🇺🇦"
    },
    {
      "locale": "be",
      "image": "https://api.fleetinsider.eu/storage/lang/lEqw3EEYNo3KmarQ6oQll3cv6ZkgevT0MG3w5VoA.png",
      "image_flag": "🇧🇾"
    }]
  if (!languages || !languages.length) return null;

  return (
    <>
      <Header
        languages={languages}
        handleScroll={handleScroll}
        changeLanguage={changeLanguage}/>
      <BlockFirst />
      <BlockSecond />
      <Structure />
      <Dashboard />
      <ResponsiveDashboard />
      <BlockThird />
      <BlockFourth />
      <BlockFifth scroll={handleScroll} changeLanguage={changeLanguage}/>
    </>
  )
}

export default LandingPage
