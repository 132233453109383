import React, { useEffect } from 'react';
import './i18n';
import LandingPage from './pages/LandingPage/LandingPage';
import ReactGA from 'react-ga4';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-CVT0ZDT0N0'); 
    ReactGA.send('pageview');
  }, []);

  return <LandingPage />;
};

export default App;

// import TagManager from 'react-gtm-module'
// import React from 'react';
// import './i18n';
// import LandingPage from './pages/LandingPage/LandingPage'

// const tagManagerArgs = {
//   gtmId: 'G-CVT0ZDT0N0'
// }
// TagManager.initialize(tagManagerArgs)

// const App = () => (<LandingPage />);

// export default App;