import React from 'react'
import LineLi from '../../assets/pictures/LineLiStructure.svg';
import { ReactComponent as StructureImg } from '../../assets/pictures/structure.svg';
import { ReactComponent as StructureImgMobile } from '../../assets/pictures/structureMobile.svg';
import { useTranslation } from 'react-i18next';
import style from './Structure.module.css';
import './animationStructure.css';

export const Structure = () => {

  const { t } = useTranslation();
  return (
    <div className={style.structure}>
    <div className={style.container}>
        <div className={style.titleContainer}>
          <div><img src={LineLi} alt="li" /></div>
          <div>
            <div className={style.title}>
              {t("Dashboard.Section2_header_1")}<br/>
              {t("Dashboard.Section2_header_2")}<br/>
            </div>
            <div className={style.description}>
              {t("Structure.paragraph1")}<br/><br/>
              {t("Structure.paragraph2")}<br/><br/>
              {t("Structure.paragraph3")}
            </div>
          </div>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.bg}/>
          <StructureImg className={`${style.desktop} svg`}/>
          <StructureImgMobile className={`${style.mobile} svg`}/>
        </div>
    </div>
  </div>

  )
}
