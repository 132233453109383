import React from 'react';
import LineMidEndLi from '../../assets/pictures/LineMidEndLi.svg';
import { useTranslation } from 'react-i18next';
import style from './BlockFourth.module.css';

export const BlockFourth = () => {
  const { t } = useTranslation()
  const handleSugnUp = () => {
    // navigate('/login', {state: {name: 'signup'}})
    window.location.href="https://app.fleetinsider.eu/register"
  }
  return(
    <div className={style.fourthBlock}>
      <div className={style.container}>
        <div className={style.titleContainer}>
          <div><img src={LineMidEndLi} alt="li" /></div>
          <div>
            <div className={style.title}>
              {t("FourthBlock.Reduce your trip")} <br/> {t("FourthBlock.Expenses with Fleet Insider")}
            </div>
            <div className={style.subTitle}>
              {t("FourthBlock.Sign up to start your new experience")}
            </div>
            <button className={style.button} onClick={handleSugnUp}>{t("FourthBlock.Sign UP")}</button>
          </div>
        </div>
      </div>
    </div>
  )
}
