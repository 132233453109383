import logoAS24 from '../../assets/pictures/logoAS24.svg';
import logoTfc from '../../assets/pictures/logoTfc.svg';
import logoDKV from '../../assets/pictures/logoDKV.svg';
import logoE100 from '../../assets/pictures/logoE100.svg';
import logoEW from '../../assets/pictures/logoEW.svg';
import logoMoya from '../../assets/pictures/logoMoya.svg';
import logoIds from '../../assets/pictures/logoIds.svg';
import logoTankpool from '../../assets/pictures/logoTankpool.svg';

export const logos = [
  {id: 1, logo: logoTfc},
  {id: 2, logo: logoIds},
  {id: 3, logo: logoTankpool},
  {id: 4, logo: logoE100},
  {id: 5, logo: logoDKV},
  {id: 6, logo: logoAS24},
  {id: 7, logo: logoMoya},
  {id: 8, logo: logoEW},
]

export const lies = [
  {id: 1, title: "BI & Reporting", subTitle: 'Make easy and fast data driven decisions'},
  {id: 2, title: "Data Platform", subTitle: 'Operate with a single interface for all data sources'},
  {id: 3, title: "AI & Cloud", subTitle: 'Savings from AI tips and cloud usability'},
]